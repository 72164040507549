// @ts-nocheck
import React, { useState, useEffect } from "react";
import { supabase } from "../../lib/supabase";
import { Card, CardContent } from "../Components/Card";
import { Loader2 } from "lucide-react";
import EditableField from "../Components/EditableField";

const PhotoGallery = ({ photoData, onUpdatePhoto }) => {
  const [groupedPhotos, setGroupedPhotos] = useState({});
  const [updatedPhotos, setUpdatedPhotos] = useState({});

  useEffect(() => {
    const updatePhotosWithData = async () => {
      if (!photoData?.photos) return;

      // Create arrays of promises for parallel execution
      const photoPromises = Object.entries(photoData.photos).map(
        async ([key, photo]) => {
          try {
            // Execute both promises in parallel
            const [urlResponse, photoDetailsResponse] = await Promise.all([
              supabase.storage
                .from("loadPhotos")
                .createSignedUrl(photo.photo_url, 3600),
              supabase
                .from("photos")
                .select("*")
                .eq("photo_url", photo.photo_url)
                .single(),
            ]);

            console.log("urlResponse", urlResponse);
            console.log("photoDetailsResponse", photoDetailsResponse);

            if (urlResponse.error) {
              console.error(
                `Error creating signed URL for ${photo.photo_url}:`,
                urlResponse.error
              );
              return [key, null];
            }

            if (photoDetailsResponse.error) {
              console.error(
                `Error fetching photo details for ${photo.photo_url}:`,
                photoDetailsResponse.error
              );
            }

            return [
              key,
              {
                signedUrl: urlResponse.data?.signedUrl,
                details: photoDetailsResponse.data || null,
              },
            ];
          } catch (err) {
            console.error(
              `Exception while processing ${photo.photo_url}:`,
              err
            );
            return [key, null];
          }
        }
      );

      // Wait for all promises to resolve
      const results = await Promise.all(photoPromises);

      // Filter out failed requests and create the photos object
      const newUpdatedPhotos = Object.fromEntries(
        results.filter(([_, value]) => value !== null)
      );

      // Update both states at once to avoid multiple rerenders
      const grouped = Object.entries(newUpdatedPhotos).reduce(
        (acc, [key, photo]) => {
          const { stage_name } = photo.details || {};
          if (!acc[stage_name]) {
            acc[stage_name] = [];
          }
          acc[stage_name].push({ key, ...photo });
          return acc;
        },
        {}
      );

      setUpdatedPhotos(newUpdatedPhotos);
      setGroupedPhotos(grouped);
    };
    console.log("updatedPhotos", groupedPhotos);
    updatePhotosWithData();
  }, [photoData, groupedPhotos]);

  return (
    <div className="space-y-8">
      {Object.entries(groupedPhotos).map(([stage_name, stagePhotos]) => (
        <div key={stage_name} className="space-y-4">
          <h2 className="text-xl font-semibold">Stage: {stage_name}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {stagePhotos.map(({ key }) => (
              <Card key={key} className="overflow-hidden">
                <div className="relative aspect-video bg-slate-100">
                  {updatedPhotos[key]?.signedUrl ? (
                    <img
                      src={updatedPhotos[key].signedUrl}
                      alt={`${photoData.photos[key].id}`}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full">
                      <Loader2 className="w-6 h-6 animate-spin" />
                    </div>
                  )}
                </div>
                <CardContent className="p-4 space-y-3">
                  {/* Metadata Section */}
                  <div className="text-sm text-gray-600 space-y-1">
                    <p>
                      <strong>ID:</strong> {photoData.photos[key].id}
                    </p>
                    <p>
                      <strong>Created:</strong>{" "}
                      {new Date(
                        photoData.photos[key].created_at
                      ).toLocaleString()}
                    </p>

                    {/* Add location data if available */}
                    {updatedPhotos[key]?.details?.origin_latitude && (
                      <p>
                        <strong>Location:</strong>{" "}
                        {updatedPhotos[key].details.origin_latitude.toFixed(6)},{" "}
                        {updatedPhotos[key].details.origin_longitude.toFixed(6)}
                      </p>
                    )}

                    {/* Add device info if available */}
                    {updatedPhotos[key]?.details?.metadata?.deviceInfo && (
                      <>
                        <p>
                          <strong>Device:</strong>{" "}
                          {updatedPhotos[key].details.metadata.deviceInfo.model}
                        </p>
                        <p>
                          <strong>OS:</strong>{" "}
                          {
                            updatedPhotos[key].details.metadata.deviceInfo
                              .osVersion
                          }
                        </p>
                      </>
                    )}
                  </div>

                  {window.location.href.includes("public-report") ? (
                    <div>
                      <p>
                        <strong>Tags:</strong>{" "}
                        {updatedPhotos[key]?.details?.tags
                          ?.map((tag) => {
                            try {
                              const tagObj =
                                typeof tag === "string" ? JSON.parse(tag) : tag;
                              return tagObj.name;
                            } catch (e) {
                              console.error("Error parsing tag:", tag, e);
                              return "";
                            }
                          })
                          .filter(Boolean)
                          .join(", ") || ""}
                      </p>
                    </div>
                  ) : (
                    <EditableField
                      label={<strong>Tags</strong>}
                      value={
                        updatedPhotos[key]?.details?.tags
                          ?.map((tag) => {
                            try {
                              const tagObj =
                                typeof tag === "string" ? JSON.parse(tag) : tag;
                              return tagObj.name;
                            } catch (e) {
                              console.error("Error parsing tag:", tag, e);
                              return "";
                            }
                          })
                          .filter(Boolean)
                          .join(", ") || ""
                      }
                      onSave={(value) =>
                        onUpdatePhoto(key, {
                          ...updatedPhotos[key]?.details,
                          tags: value.split(",").map((tag) => ({
                            id: `temp-${Date.now()}`,
                            name: tag.trim(),
                            type: tag.trim().toLowerCase(),
                            createdAt: new Date().toISOString(),
                          })),
                        })
                      }
                    />
                  )}
                  {window.location.href.includes("public-report") ? (
                    <div>
                      <p>
                        <strong>Notes:</strong>{" "}
                        {updatedPhotos[key]?.details?.notes || ""}
                      </p>
                    </div>
                  ) : (
                    <EditableField
                      label={<strong>Notes</strong>}
                      value={updatedPhotos[key]?.details?.notes || ""}
                      onSave={(value) =>
                        onUpdatePhoto(key, {
                          ...updatedPhotos[key]?.details,
                          notes: value,
                        })
                      }
                      type="textarea"
                    />
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotoGallery;
