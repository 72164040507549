// @ts-nocheck
import { useState } from "react";
import { Input } from "./Input";
import Textarea from "./TextArea";
import { Button } from "./Button";
import { FiEdit2, FiSave, FiX } from "react-icons/fi";

const EditableField = ({ label, value, onSave, type = "text" }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  const handleSave = () => {
    onSave(tempValue);
    setIsEditing(false);
  };

  return (
    <div className="space-y-1">
      <label className="text-sm font-medium text-slate-500">{label}</label>
      <div className="flex items-center gap-2">
        {isEditing ? (
          <>
            {type === "textarea" ? (
              <Textarea
                value={tempValue || ""}
                onChange={(e) => setTempValue(e.target.value)}
                className="flex-1 bg-white min-h-[60px]"
              />
            ) : (
              <Input
                type={type}
                value={tempValue || ""}
                onChange={(e) => setTempValue(e.target.value)}
                className="flex-1 bg-white"
              />
            )}
            <div className="flex gap-1">
              <Button size="sm" variant="ghost" onClick={handleSave}>
                <FiSave className="w-4 h-4" />
              </Button>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => setIsEditing(false)}
              >
                <FiX className="w-4 h-4" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="flex-1 text-sm text-slate-900">
              {value || "Not specified"}
            </p>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setIsEditing(true)}
            >
              <FiEdit2 className="w-4 h-4" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditableField;
