// @ts-nocheck
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PhotoService } from "../api/PhotosService";
import { useState, useEffect } from "react";
import { supabase } from "../lib/supabase";

/**
 * Hook for uploading a photo.
 * @returns {UseMutationResult} Mutation result object for uploading a photo.
 */
export const useUploadPhoto = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: PhotoService.uploadPhoto,
    onSuccess: () => {
      console.log("Photo uploaded successfully");
      queryClient.invalidateQueries({ queryKey: ["photos"] });
    },
    onError: (error) => {
      console.error("Error uploading photo:", error);
    },
  });
};

/**
 * Hook for retrieving signed URLs for photos.
 * @returns {UseQueryResult} Query result object containing photo data.
 */
export const useGetPhotos = () => {
  return useQuery({
    queryKey: ["photos"],
    queryFn: () => {
      console.log("Fetching photos");
      return PhotoService.getPhotos();
    },
    onSuccess: (data) => {
      console.log(`Retrieved ${data.length} photos`);
    },
    onError: (error) => {
      console.error("Error fetching photos:", error);
    },
  });
};

/**
 * Hook for retrieving details of a specific photo.
 * @param {string} loadId - The ID of the load.
 * @param {string} side - The side of the load.
 * @returns {UseQueryResult} Query result object containing photo details.
 */
export const useGetPhotoDetails = (loadId, side) => {
  return useQuery({
    queryKey: ["photoDetails", loadId, side],
    queryFn: () => {
      console.log(`Fetching photo details for load ${loadId}, side ${side}`);
      return PhotoService.getPhotoDetails(loadId, side);
    },
    enabled: !!loadId && !!side,
    onSuccess: (data) => {
      console.log(`Retrieved photo details for load ${loadId}, side ${side}`);
    },
    onError: (error) => {
      console.error(
        `Error fetching photo details for load ${loadId}, side ${side}:`,
        error
      );
    },
  });
};

/**
 * Hook for downloading a photo using the public URL.
 * @returns {UseMutationResult} Mutation result object for downloading a photo.
 */
export const useDownloadPhoto = () => {
  return useMutation({
    mutationFn: PhotoService.downloadPhoto,
    onSuccess: () => {
      console.log("Photo downloaded successfully");
    },
    onError: (error) => {
      console.error("Error downloading photo:", error);
    },
  });
};

export const useSignedUrl = (filePath) => {
  const [signedUrl, setSignedUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getSignedUrl = async () => {
      if (!filePath) {
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase.storage
          .from("loadPhotos")
          .createSignedUrl(filePath, 3600); // 1 hour expiry

        if (error) throw error;
        setSignedUrl(data.signedUrl);
      } catch (err) {
        console.error("Error getting signed URL:", err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getSignedUrl();
  }, [filePath]);

  return { signedUrl, error, isLoading };
};
