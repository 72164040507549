import React from "react";

const stats = [
  { id: 1, name: "Estimated Direct Merchandise Lost Annually", value: "$30B" },
  { id: 2, name: "Average Loss Per Shipment", value: "$587,000" },
  {
    id: 3,
    name: "Total Reported Cargo Theft Events Annually in the US",
    value: "25,000",
  },
  {
    id: 4,
    name: "% of cargo loss claims due to theft",
    value: "~20%",
  },
];

export default function Example() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
              Theft and Loss Costs are Growing
            </h2>
            <p className="mt-4 text-lg/8 text-gray-300">
              Cargo theft is costing the global supply chain billions annually,
              and growing significantly year over year.
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-white/5 p-8">
                <dt className="text-sm/6 font-semibold text-gray-300">
                  {stat.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
