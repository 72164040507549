// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent } from "../../Components/Card";
import PhotoGallery from "../../Components/PhotoGallery";
import { Loader2 } from "lucide-react";
import Toast from "../../Components/Toast";
import Textarea from "../../Components/TextArea";
import { Button } from "../../Components/Button";
import { Input } from "../../Components/Input";
import { FiPrinter } from "react-icons/fi";
import { NotificationService } from "../../../api/NotificationService";
import ShipmentConfirmation from "../../Components/ShipmentConfirmation";
import { supabase } from "../../../lib/supabase";
import MapComponent from "../../Components/MapComponent";
import { Dialog, DialogBackdrop } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const PublicReport = () => {
  const { token } = useParams();
  const [shipment, setShipment] = useState(null);
  const [verificationData, setVerificationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [publicReportUrl, setPublicReportUrl] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [groupedPhotos, setGroupedPhotos] = useState({});
  const [activeTab, setActiveTab] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [scale, setScale] = useState(1);
  const [dragStart, setDragStart] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Set the public report URL when the component mounts
  useEffect(() => {
    setPublicReportUrl(window.location.href);
  }, []);

  const submitFeedback = async () => {
    setIsSubmittingFeedback(true);
    try {
      const { data, error } = await supabase.functions.invoke(
        "submitFeedback",
        {
          body: {
            shipment_id: shipment.id,
            feedback,
          },
        }
      );

      if (error) {
        console.error("Error invoking submitFeedback:", error);
        throw new Error("Failed to submit feedback");
      }

      Toast.success("Feedback submitted successfully");
      setFeedback("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      Toast.error("Failed to submit feedback");
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  // Function to send email
  const sendEmail = async () => {
    console.log("Sending email to:", email);
    setIsSendingEmail(true);
    try {
      const subject = "Your Public Report is Ready";
      const htmlContent = `<p>Your public report is available at: ${publicReportUrl}</p>`;

      await NotificationService.sendEmail(
        email,
        "noreply@app.indemni.com",
        subject,
        htmlContent
      );

      Toast.success("Email sent successfully");
      setShowEmailModal(false);
      setEmail("");
    } catch (error) {
      console.error("Error sending email:", error);
      Toast.error("Failed to send email");
    } finally {
      setIsSendingEmail(false);
    }
  };

  // Updated fetch function
  useEffect(() => {
    fetchShipmentByToken();
  }, [token]);

  const fetchShipmentByToken = async () => {
    console.log("Fetching shipment by token:", token);
    try {
      const { data, error } = await supabase.functions.invoke(
        "getPublicReport",
        {
          body: { token },
        }
      );

      if (error) throw error;

      const verificationData = data.shipment.verifications; // Since it's a 1:1 relationship
      setVerificationData(verificationData);

      // Extract the nested data
      const responseData = data;

      // Set shipment data
      setShipment(responseData.shipment);

      // Transform photos array
      const photosArray = responseData.photos || [];
      setPhotos(photosArray);

      // The grouped photos are already organized by stage name
      const groupedPhotosData = responseData.groupedPhotos || {};
      setGroupedPhotos(groupedPhotosData);

      // Set the first stage as active tab if there are any stages
      const stages = Object.keys(groupedPhotosData);
      if (stages.length > 0 && !activeTab) {
        setActiveTab(stages[0]);
      }

      console.log("Processed data:", {
        shipment: responseData.shipment,
        photosCount: photosArray.length,
        groupedStages: Object.keys(groupedPhotosData),
      });
    } catch (error) {
      console.error("Error fetching shipment:", error);
      setShipment(null);
      Toast.error("Failed to load shipment details");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmShipment = async () => {
    setIsConfirming(true);
    try {
      const { data, error } = await supabase.functions.invoke(
        "confirmShipment",
        {
          body: { shipment_id: shipment.id },
        }
      );

      if (error) {
        console.error("Error invoking confirmShipment:", error);
        throw new Error("Failed to confirm shipment");
      }

      setShipment({
        ...shipment,
        confirmed: true,
        confirmed_at: data.confirmed_at,
      });
      setIsConfirmed(true);
      Toast.success("Shipment confirmed successfully");
    } catch (error) {
      console.error("Error confirming shipment:", error);
      Toast.error("Failed to confirm shipment");
    } finally {
      setIsConfirming(false);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleZoomIn = () => {
    setScale((prev) => Math.min(prev + 0.5, 3));
  };

  const handleZoomOut = () => {
    setScale((prev) => Math.max(prev - 0.5, 0.5));
  };

  const handleMouseDown = (e) => {
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragStart) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragStart(null);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Loading...</p>
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (!shipment) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>No shipment found with the provided token.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white h-full relative">
      {/* Email Modal */}
      {showEmailModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-xl font-semibold mb-4">Email Report</h2>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Recipient's email"
              className="mb-4"
            />
            <Button
              onClick={sendEmail}
              disabled={isSendingEmail}
              className="mr-4"
            >
              {isSendingEmail ? "Sending..." : "Send Email"}
            </Button>
            <button onClick={() => setShowEmailModal(false)}>Close</button>
          </div>
        </div>
      )}

      {/* Shipment Details */}
      <Card>
        <div className="flex justify-center mt-6">
          <img
            src={require("../../../public/static/image.png")}
            alt="Logo"
            className="h-auto w-auto max-w-[200px]"
          />
        </div>
        {/* Email and Print Buttons */}
        <div className="flex justify-end space-x-2 mr-4 mt-8">
          <Button onClick={handlePrint}>
            <FiPrinter className="w-4 h-4 mr-2" />
            Print Report
          </Button>
        </div>
        <CardContent>
          {/* Shipment Details Section */}
          <div>
            <div className="px-4 sm:px-0">
              <h1 className="text-3xl font-semibold text-gray-900">
                Shipment Details
              </h1>
              <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">
                Tracking and delivery information
              </p>
            </div>
            <div className="mt-6">
              <dl className="grid grid-cols-1 sm:grid-cols-2">
                {/* High Level Information */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Load ID
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.load_id || "N/A"}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Status
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.status || "N/A"}
                  </dd>
                </div>

                {/* High Level Information */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Identity Verification Status
                  </dt>
                  <dd className="mt-1 text-sm/6 sm:mt-2">
                    <span
                      className={
                        verificationData.persona_verification_complete
                          ? "text-green-600 font-medium"
                          : "text-red-600 font-medium"
                      }
                    >
                      {verificationData.persona_verification_complete
                        ? "Identity Verified"
                        : "Identity Not Verified"}
                    </span>
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Document Verification Status
                  </dt>
                  <dd className="mt-1 text-sm/6 sm:mt-2">
                    <span
                      className={
                        verificationData.ocr_verification_complete
                          ? "text-green-600 font-medium"
                          : "text-red-600 font-medium"
                      }
                    >
                      {verificationData.ocr_verification_complete
                        ? "Document Verified"
                        : "Document Not Verified"}
                    </span>
                  </dd>
                </div>

                {/* Location Information */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Origin
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.origin_address || "N/A"}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Destination
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.destination_address || "N/A"}
                  </dd>
                </div>

                {/* Shipping Information */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Carrier Number
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.carrier_number || "N/A"}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Container Number
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.container_number || "N/A"}
                  </dd>
                </div>

                {/* Additional Details */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">Notes</dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:mt-2">
                    {shipment.notes || "N/A"}
                  </dd>
                </div>

                {/* Status Information */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Shipment Status
                  </dt>
                  <dd className="mt-2 text-sm space-y-2">
                    <div className="flex flex-wrap gap-x-8 gap-y-4">
                      <div className="flex items-center">
                        <span className="font-medium text-gray-900">
                          Condition:
                        </span>
                        <span className="ml-3 text-gray-700">
                          {shipment.condition || "N/A"}
                        </span>
                      </div>
                    </div>

                    {shipment.confirmed_at && (
                      <div className="flex items-center mt-4">
                        <span className="font-medium text-gray-900">
                          Confirmed At:
                        </span>
                        <span className="ml-3 text-gray-700">
                          {new Date(shipment.confirmed_at).toLocaleString()}
                        </span>
                      </div>
                    )}
                  </dd>
                </div>

                {/* Tags */}
                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">Tags</dt>
                  <dd className="mt-2 text-sm text-gray-700">
                    {shipment.tags || "N/A"}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {/* Photos Section */}
          {photos && (
            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-2">Photos by Stage</h2>

              {/* Map showing all photo locations */}
              <div className="h-96 w-full rounded-lg overflow-hidden mb-6 relative z-0">
                <MapComponent
                  markers={photos.map((photo) => ({
                    lat: parseFloat(photo.origin_latitude),
                    lng: parseFloat(photo.origin_longitude),
                    label: photo.stage_name,
                  }))}
                />
              </div>

              {/* Tabs Navigation */}
              <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
                <div className="mt-4">
                  <div className="hidden sm:block">
                    <nav className="-mb-px flex space-x-8">
                      {Object.keys(groupedPhotos).map((stageName) => (
                        <button
                          key={stageName}
                          onClick={() => setActiveTab(stageName)}
                          className={classNames(
                            activeTab === stageName
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                          )}
                        >
                          {stageName}
                        </button>
                      ))}
                    </nav>
                  </div>

                  {/* Mobile dropdown */}
                  <div className="sm:hidden">
                    <select
                      value={activeTab || ""}
                      onChange={(e) => setActiveTab(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    >
                      <option value="">Select a stage</option>
                      {Object.keys(groupedPhotos).map((stageName) => (
                        <option key={stageName} value={stageName}>
                          {stageName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Photos Content */}
              {activeTab && groupedPhotos[activeTab] && (
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {groupedPhotos[activeTab].map((photo) => (
                    <div
                      key={photo.id}
                      className="border rounded-lg p-4 space-y-3"
                    >
                      <img
                        src={photo.signedURL}
                        alt={photo.caption || "Shipment photo"}
                        className="w-full h-48 object-cover rounded-lg cursor-pointer"
                        onClick={() => handlePhotoClick(photo)}
                      />

                      <div className="space-y-2">
                        <p className="text-sm text-slate-600">
                          Location: {photo.origin_latitude},{" "}
                          {photo.origin_longitude}
                        </p>

                        {photo.tags && (
                          <div className="flex flex-wrap gap-2">
                            {photo.tags.map((tag, index) => (
                              <span
                                key={index}
                                className="px-2 py-1 bg-slate-100 rounded-full text-xs text-slate-600"
                              >
                                {JSON.parse(tag).name}
                              </span>
                            ))}
                          </div>
                        )}

                        {photo.metadata && (
                          <div className="text-xs text-slate-500">
                            <p>
                              Taken:{" "}
                              {new Date(
                                photo.metadata.timing.takenAt
                              ).toLocaleString()}
                            </p>
                            <p>Timezone: {photo.metadata.timing.timezone}</p>
                            <p>Device: {photo.metadata.deviceInfo.model}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Feedback Form */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-2">
              Add Notes or Questions
            </h2>
            <Textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Enter your notes or questions here..."
              className="mb-2"
            />
            <Button
              onClick={submitFeedback}
              disabled={isSubmittingFeedback}
              className="mt-2"
            >
              {isSubmittingFeedback ? "Submitting..." : "Submit"}
            </Button>
          </div>

          {/* Confirmation Checkbox */}
          {!isConfirmed && (
            <ShipmentConfirmation
              isConfirmed={isConfirmed}
              isConfirming={isConfirming}
              confirmShipment={confirmShipment}
            />
          )}
        </CardContent>
      </Card>

      {selectedPhoto && (
        <Dialog
          open={true}
          onClose={() => {
            setSelectedPhoto(null);
            setPosition({ x: 0, y: 0 });
          }}
          className="relative z-50"
        >
          <DialogBackdrop className="fixed inset-0 bg-black/90 backdrop-blur-sm transition-opacity" />

          <div className="fixed inset-0 z-10">
            <div className="flex min-h-full items-center justify-center">
              {/* Main container */}
              <div className="relative w-full h-full p-4">
                {/* Close button */}
                <button
                  onClick={() => setSelectedPhoto(null)}
                  className="absolute top-4 right-4 z-20 p-2 bg-white/10 hover:bg-white/20 backdrop-blur-md rounded-full transition-colors"
                >
                  <XMarkIcon className="h-6 w-6 text-white" />
                </button>

                {/* Zoom controls */}
                <div className="absolute bottom-4 right-4 z-20 flex gap-3">
                  <button
                    onClick={handleZoomOut}
                    disabled={scale <= 0.5}
                    className="w-10 h-10 flex items-center justify-center bg-white/10 hover:bg-white/20 backdrop-blur-md rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <span className="text-white text-xl font-medium">−</span>
                  </button>
                  <button
                    onClick={handleZoomIn}
                    disabled={scale >= 3}
                    className="w-10 h-10 flex items-center justify-center bg-white/10 hover:bg-white/20 backdrop-blur-md rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <span className="text-white text-xl font-medium">+</span>
                  </button>
                </div>

                {/* Image container */}
                <div
                  className="w-full h-full flex items-center justify-center overflow-hidden cursor-grab active:cursor-grabbing"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <img
                    src={selectedPhoto.signedURL}
                    alt={selectedPhoto.caption || "Shipment photo"}
                    className="max-h-[90vh] max-w-[90vw] object-contain select-none"
                    style={{
                      transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                      transition: dragStart ? "none" : "transform 0.2s",
                      transformOrigin: "center",
                    }}
                    draggable="false"
                  />
                </div>

                {/* Photo details */}
                <div className="absolute bottom-4 left-4 z-20 p-4 bg-white/10 backdrop-blur-md rounded-lg text-white">
                  <div className="space-y-1">
                    <p className="text-sm font-medium">
                      {new Date(
                        selectedPhoto.metadata?.timing?.takenAt
                      ).toLocaleString()}
                    </p>
                    <p className="text-sm opacity-80">
                      {selectedPhoto.origin_latitude},{" "}
                      {selectedPhoto.origin_longitude}
                    </p>
                    {selectedPhoto.metadata?.deviceInfo?.model && (
                      <p className="text-sm opacity-80">
                        {selectedPhoto.metadata.deviceInfo.model}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default PublicReport;
