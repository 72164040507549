import React from "react";

export default function Example() {
  return (
    <footer
      className="relative isolate overflow-hidden bg-gray-900"
      style={{
        background:
          "radial-gradient(circle at bottom, rgba(17, 157, 165, 0.6) 0%, #111827 65%)",
      }}
    >
      <div className="relative z-0 mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
        <div className="relative isolate overflow-hidden rounded-2xl px-6 py-24">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-base/7 font-semibold text-indigo-400">
              Get started
            </h2>
            <p className="mt-2 text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
              Boost your productivity. Start using our app today.
            </p>
            <p className="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-400">
              Take control of your transportation business with our easy-to-use
              platform that handles all your risk management and paperwork in
              one place.
            </p>
            <div className="mt-8 flex justify-center gap-4">
              <a
                href="https://calendly.com/meet-indemni/30min?background_color=000000&text_color=FFFFFF&primary_color=119DA4"
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Get started
              </a>
              <a
                href="mailto:support@indemni.com"
                className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
