import React from "react";
import driverInformation from "../../../public/static/screenshots/driver-information.png";
import cargoPhoto from "../../../public/static/screenshots/cargo-photo.png";
import driverVerification from "../../../public/static/screenshots/verifications.png";
import shipmentInformation from "../../../public/static/screenshots/shipment-information.png";

export default function Example() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32 relative overflow-hidden">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8 relative z-10">
        <h2 className="text-base/7 font-semibold text-indigo-400">
          Stop Theft and Loss
        </h2>
        <p className="mt-2 max-w-lg text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          Everything you need to protect your cargo
        </p>
        <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
          <div className="flex p-px lg:col-span-4">
            <div className="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]">
              <img
                alt=""
                src={driverInformation}
                className="h-80 w-full object-cover object-left"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Database
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Track drivers and vehicles
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Track driver identity and credentials in real-time, track
                  driver history and performance, and get instant alerts about
                  potential risks before releasing your cargo.
                </p>
              </div>
            </div>
          </div>
          <div className="flex p-px lg:col-span-2">
            <div className="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 lg:rounded-tr-[2rem]">
              <img
                alt=""
                src={cargoPhoto}
                className="h-80 w-full object-cover object-[50%_5%]"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Photo Evidence
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Track the condition of your cargo
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Take a photo of your cargo when it is loaded and when it is
                  delivered, and send reports to other stakeholders.
                </p>
              </div>
            </div>
          </div>
          <div className="flex p-px lg:col-span-2">
            <div className="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 lg:rounded-bl-[2rem]">
              <img
                alt=""
                src={driverVerification}
                className="h-80 w-full object-cover object-[25%_2%]"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Verifications
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Verify drivers and vehicles
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Verify drivers and vehicles against a database of known stolen
                  vehicles and drivers, and verify identity of those handling
                  your cargo.
                </p>
              </div>
            </div>
          </div>
          <div className="flex p-px lg:col-span-4">
            <div className="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]">
              <img
                alt=""
                src={shipmentInformation}
                className="h-[58%] w-full object-cover object-left"
              />
              <div className="p-10">
                <h3 className="text-sm/4 font-semibold text-gray-400">
                  Shipments
                </h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-white">
                  Track shipment information
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-400">
                  Track shipment information, including the verification status
                  and condition of each shipment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 -z-0 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 -z-0 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[-10rem] top-[calc(100%-40rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[-30rem] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
