// @ts-nocheck
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useShipments } from "../../hooks/shipments";
import { Loader2Icon } from "lucide-react";
import { Card, CardContent } from "../Components/Card";
import { Button } from "../Components/Button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../Components/Tabs";
import {
  FiDownload,
  FiRefreshCw,
  FiPackage,
  FiTruck,
  FiCheckCircle,
  FiPieChart,
  FiList,
  FiLoader,
} from "react-icons/fi";
import Analytics from "./Analytics";
import ShipmentList from "./ShipmentList";
import Toast from "../Components/Toast";
import { useAuth } from "../../providers/AuthProvider";

const ExternalDashboard = () => {
  const { id: cargoOwnerId } = useParams();
  const navigate = useNavigate();
  const { loading, externalUser } = useAuth();

  const [activeTab, setActiveTab] = useState("shipments");
  const [filters] = useState({
    limit: 100,
    orderBy: "created_at",
    orderDirection: "desc",
    cargoOwnerId: cargoOwnerId,
  });

  const {
    data: { shipments: shipmentsData, total } = { shipments: [], total: 0 },
    isLoading,
    error,
    refetch,
  } = useShipments(1, 100, filters);

  // Check authorization
  React.useEffect(() => {
    if (externalUser) {
      const hasAccess = externalUser.cargo_owner_id === Number(cargoOwnerId);
      if (!hasAccess) {
        navigate("/unauthorized");
      }
    } else if (!loading) {
      // If loading is false and externalUser is still not available
      navigate("/unauthorized");
    }
  }, [externalUser, cargoOwnerId, loading, navigate]);

  // Update stats to use the correct data structure
  const stats = {
    totalShipments: total || 0,
    inTransit:
      shipmentsData?.filter((s) => s.status === "in_transit").length || 0,
    completed:
      shipmentsData?.filter((s) => s.status === "completed").length || 0,
    recentUpdates: shipmentsData?.slice(0, 5) || [],
  };

  const handleRefresh = () => {
    refetch();
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-900 flex items-center justify-center">
        <Loader2Icon className="w-8 h-8 animate-spin text-white" />
      </div>
    );
  }

  if (!externalUser) {
    Toast.error("Unauthorized access");
    return null;
  }

  if (error) {
    Toast.error(error.message);
    return null;
  }

  return (
    <div className="min-h-screen bg-slate-900">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-10 bg-slate-800 border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-white">
              Customer Dashboard
            </h1>
            <div className="space-x-4 flex items-center">
              <Button
                onClick={handleRefresh}
                variant="outline"
                className="flex items-center text-white"
              >
                <FiRefreshCw className="w-4 h-4 mr-2" />
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-16">
        <div className="space-y-8">
          {/* Stats Overview */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card>
              <CardContent className="flex items-center p-6">
                <div className="p-4 rounded-full bg-blue-100 mr-5">
                  <FiPackage className="w-6 h-6 text-blue-600" />
                </div>
                <div className="space-y-1">
                  <p className="text-sm font-medium text-gray-600">
                    Total Shipments
                  </p>
                  <p className="text-2xl font-semibold text-gray-900">
                    {isLoading ? (
                      <FiLoader className="w-6 h-6 animate-spin" />
                    ) : (
                      stats.totalShipments
                    )}
                  </p>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="flex items-center p-6">
                <div className="p-4 rounded-full bg-yellow-100 mr-5">
                  <FiTruck className="w-6 h-6 text-yellow-600" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600">
                    In Transit
                  </p>
                  <p className="text-2xl font-semibold text-gray-900">
                    {isLoading ? (
                      <FiLoader className="w-6 h-6 animate-spin" />
                    ) : (
                      stats.inTransit
                    )}
                  </p>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="flex items-center p-6">
                <div className="p-4 rounded-full bg-green-100 mr-5">
                  <FiCheckCircle className="w-6 h-6 text-green-600" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600">Completed</p>
                  <p className="text-2xl font-semibold text-gray-900">
                    {isLoading ? (
                      <FiLoader className="w-6 h-6 animate-spin" />
                    ) : (
                      stats.completed
                    )}
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Main Content Tabs */}
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="mb-6 bg-gray-300 p-1 rounded-lg">
              <TabsTrigger
                value="shipments"
                className="flex items-center px-4 py-2 rounded-md data-[state=active]:bg-white data-[state=active]:text-blue-700 data-[state=active]:shadow-sm hover:text-blue-600 transition-colors"
              >
                <FiList className="w-4 h-4 mr-2" />
                Shipment List
              </TabsTrigger>
              <TabsTrigger
                value="overview"
                className="flex items-center px-4 py-2 rounded-md data-[state=active]:bg-white data-[state=active]:text-blue-700 data-[state=active]:shadow-sm hover:text-blue-600 transition-colors"
              >
                <FiPieChart className="w-4 h-4 mr-2" />
                Analytics Overview
              </TabsTrigger>
            </TabsList>

            <TabsContent value="overview">
              <Analytics />
            </TabsContent>

            <TabsContent value="shipments">
              <ShipmentList />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ExternalDashboard;
