// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { supabase } from "../../../lib/supabase";
import { Button } from "../../Components/Button";
import { Input } from "../../Components/Input";
import Textarea from "../../Components/TextArea";
import Toast from "../../Components/Toast";
import MapComponent from "../../Components/MapComponent";

const ReportDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reportUrl, setReportUrl] = useState(null);
  const [emailDetails, setEmailDetails] = useState({
    to: "",
    subject: "",
    message: "",
  });
  const [photos, setPhotos] = useState([]);
  const [groupedPhotos, setGroupedPhotos] = useState({});

  useEffect(() => {
    const fetchShipment = async () => {
      try {
        const { data, error } = await supabase
          .from("shipments")
          .select("*")
          .eq("id", id)
          .single();

        if (error) throw error;
        setShipment(data);
      } catch (error) {
        console.error("Error fetching shipment:", error);
        Toast.error({
          title: "Error",
          description: "Failed to load shipment details",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    const fetchReportUrl = async () => {
      try {
        const { data, error } = await supabase
          .from("shipment_reports")
          .select("report_url")
          .eq("shipment_id", id)
          .single();

        if (error) throw error;
        setReportUrl(data.url);
      } catch (error) {
        console.error("Error fetching report URL:", error);
      }
    };

    const fetchPhotos = async () => {
      try {
        const { data, error } = await supabase
          .from("photos")
          .select("*")
          .eq("load_id", id);

        if (error) throw error;

        // Group photos by stage_name
        const grouped = data.reduce((acc, photo) => {
          const stage = photo.stage_name || "Uncategorized";
          if (!acc[stage]) acc[stage] = [];
          acc[stage].push(photo);
          return acc;
        }, {});

        setPhotos(data);
        setGroupedPhotos(grouped);
      } catch (error) {
        console.error("Error fetching photos:", error);
        Toast.error("Failed to load photos");
      }
    };

    fetchShipment();
    fetchReportUrl();
    fetchPhotos();
  }, [id]);

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailDetails((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmail = async () => {
    try {
      const response = await fetch("/api/resend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...emailDetails,
          pdfUrl: reportUrl,
        }),
      });

      if (!response.ok) throw new Error("Failed to send email");

      Toast.success("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
      Toast.error("Failed to send email");
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (!shipment) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-slate-600">Shipment not found</p>
        <Button
          variant="link"
          onClick={() => navigate("/shipments")}
          className="mt-4"
        >
          Back to Shipments
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white h-full">
      <div className="space-y-8">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-slate-900">
            Shipment Details
          </h1>
        </div>

        {/* Email Form */}
        <div className="space-y-4">
          <Input
            name="to"
            value={emailDetails.to}
            onChange={handleEmailChange}
            placeholder="Recipient Email"
          />
          <Input
            name="subject"
            value={emailDetails.subject}
            onChange={handleEmailChange}
            placeholder="Subject"
          />
          <Textarea
            name="message"
            value={emailDetails.message}
            onChange={handleEmailChange}
            placeholder="Message"
          />
          <Button onClick={sendEmail}>Send Email</Button>
        </div>
        {/* Report Preview */}
        {reportUrl ? (
          <iframe
            src={reportUrl}
            className="w-1/5 h-96 border rounded-lg"
            title="Report Preview"
          ></iframe>
        ) : (
          <div className="flex flex-col items-left">
            <h1 className="text-2xl font-bold text-slate-900">
              No Report Available - Generate Report Below
            </h1>
            <Button
              onClick={() => navigate(`/generate-report/${id}`)}
              className="mt-4 w-auto"
            >
              Generate Report
            </Button>
          </div>
        )}
      </div>

      {/* Photos Section */}
      <div className="space-y-8">
        <h2 className="text-xl font-semibold text-slate-900">
          Photos by Stage
        </h2>

        {/* Map showing all photo locations */}
        <div className="h-96 w-full rounded-lg overflow-hidden">
          <MapComponent
            markers={photos.map((photo) => ({
              lat: parseFloat(photo.origin_latitude),
              lng: parseFloat(photo.origin_longitude),
              label: photo.stage_name,
            }))}
          />
        </div>

        {/* Photos grouped by stage */}
        {Object.entries(groupedPhotos).map(([stageName, stagePhotos]) => (
          <div key={stageName} className="space-y-4">
            <h3 className="text-lg font-medium text-slate-800">{stageName}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {stagePhotos.map((photo) => (
                <div key={photo.id} className="border rounded-lg p-4 space-y-3">
                  <img
                    src={photo.photo_url}
                    alt={photo.caption || "Shipment photo"}
                    className="w-full h-48 object-cover rounded-lg"
                  />

                  <div className="space-y-2">
                    <p className="text-sm text-slate-600">
                      Location: {photo.origin_latitude},{" "}
                      {photo.origin_longitude}
                    </p>

                    {photo.tags && (
                      <div className="flex flex-wrap gap-2">
                        {photo.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="px-2 py-1 bg-slate-100 rounded-full text-xs text-slate-600"
                          >
                            {JSON.parse(tag).name}
                          </span>
                        ))}
                      </div>
                    )}

                    {photo.metadata && (
                      <div className="text-xs text-slate-500">
                        <p>
                          Taken:{" "}
                          {new Date(
                            photo.metadata.timing.takenAt
                          ).toLocaleString()}
                        </p>
                        <p>Device: {photo.metadata.deviceInfo.model}</p>
                        <p>Quality Score: {photo.metadata.quality.blurScore}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportDetails;
