// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { supabase } from "../lib/supabase";
import Toast from "../pages/Components/Toast";

const ProtectedRoute = () => {
  const [cargoOwnerId, setCargoOwnerId] = useState(null);
  const { session, loading } = useAuth();

  useEffect(() => {
    const checkExternalUser = async () => {
      if (session?.user?.user_metadata?.role === "external_user") {
        console.log("Checking external user");
        const { data, error } = await supabase
          .from("external_users")
          .select("*")
          .eq("user_id", session.user.id);

        setCargoOwnerId(data[0].cargo_owner_id);
        if (error) {
          Toast.error(error.message);
        }
      }
    };

    checkExternalUser();
  }, [session]);

  // Show loading state while checking authentication
  if (loading) {
    return <div>Loading...</div>; // You might want to replace this with a proper loading component
  }

  if (cargoOwnerId) {
    return <Navigate to={`/external/${cargoOwnerId}`} replace />;
  }

  // Check for invalid session
  if (!session?.access_token) {
    return <Navigate to="/signin" replace />;
  }

  // Check token expiration
  const currentTime = Math.floor(Date.now() / 1000);
  if (session.expires_at && currentTime >= session.expires_at) {
    return <Navigate to="/signin" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
