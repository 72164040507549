// @ts-nocheck
import { supabase } from "../../src/lib/supabase";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext({
  session: null,
  customer: null,
  externalUser: null,
  loading: true,
  isAdmin: false,
});

const isTestEnvironment = process.env.NODE_ENV === "test";

export default function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [externalUser, setExternalUser] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchSession = async () => {
      try {
        if (isTestEnvironment) {
          setSession(null);
          setLoading(false);
          return;
        }
        const {
          data: { session: currentSession },
          error: sessionError,
        } = await supabase.auth.getSession();

        if (sessionError) throw sessionError;
        if (!mounted) return;

        setSession(currentSession);

        if (currentSession) {
          const { data: customerData, error: customerError } = await supabase
            .from("customers")
            .select("*")
            .eq("user_id", currentSession.user.id)
            .single();

          if (!mounted) return;

          if (!customerError) {
            setCustomer(customerData);
          } else {
            const { data: externalData, error: externalError } = await supabase
              .from("external_users")
              .select("*")
              .eq("user_id", currentSession.user.id)
              .single();

            if (!mounted) return;
            if (!externalError) {
              setExternalUser(externalData);
            }
          }
        }
      } catch (error) {
        console.log("Error fetching session or profile:", error);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, newSession) => {
      if (mounted) {
        setSession(newSession);
      }
    });

    return () => {
      mounted = false;
      subscription?.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        session,
        customer,
        externalUser,
        loading,
        isAdmin: customer?.role === "ADMIN",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
