// @ts-nocheck
import React, { useState } from "react";
import InputField from "../../Components/InputField";
import { useGetUserSession } from "../../../hooks/users";
import { useCreateCargoOwner } from "../../../hooks/cargoOwner";
import { Router } from "react-router-dom";

const AddCargoOwner = () => {
  const { session, loading } = useGetUserSession();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    company_name: "",
    phone_number: "",
    email: "",
    is_active: true,
    industry: "",
    company_access_id: "",
    company_id: null,
  });

  const { mutate: createCargoOwner } = useCreateCargoOwner();

  if (!session) {
    Router.push("/signin");
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form", formData);

    try {
      // Use the createCarrier mutation
      console.log("Creating cargo owner", formData);
      createCargoOwner(formData, {
        onSuccess: (result) => {
          console.log("Result", result);
          setMessage(`Cargo owner added successfully.`);
          setError("");
        },
        onError: (error) => {
          setError("An error occurred. Please try again.");
          console.error("Error:", error);
        },
      });
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              Add Cargo Owner
            </h2>
            <p className="text-slate-500 text-sm">
              Enter cargo owner information.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="Company Name"
                placeholder="Company Name"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
              />
              <InputField
                label="Phone Number"
                placeholder="123-456-7890"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Email"
                placeholder="carrier@example.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <InputField
                label="Industry"
                placeholder="Industry"
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
              />
              <InputField
                label="Company Access ID"
                placeholder="Access ID"
                name="company_access_id"
                value={formData.company_access_id}
                onChange={handleInputChange}
              />
              <InputField
                label="Company ID"
                placeholder="Company ID"
                name="company_id"
                value={formData.company_id}
                onChange={handleInputChange}
                type="number"
              />
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCargoOwner;
