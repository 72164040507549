import React from "react";
import Hero from "./LandingPageComponents/Hero";
import Features from "./LandingPageComponents/Features";
import Metrics from "./LandingPageComponents/Metrics";
import CTASection from "./LandingPageComponents/CTASection";
import FAQ from "./LandingPageComponents/FAQ";

export default function LandingPage() {
  return (
    <>
      <Hero />
      <Metrics />
      <Features />
      <FAQ />
      <CTASection />
    </>
  );
}
